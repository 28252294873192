<template>
   <div>
      <v-row>
         <v-col cols="12">
            <Datatable
               :table-headers="tableHeaders"
               :table-data="formData"
               :page="tablePage"
               :page-limit="tablePageLimit"
               :page-limit-options="tablePageLimitOptions"
               :item-total="formItemTotal"
               :is-loading="tableLoading"
               :enable-search="false"
               :enable-export="false"
               :enable-filter="false"
               :enable-date-range="false"
               :enable-clear-all="false"
               :enable-refresh="false"
               :disable-pagination="tableLoading"
               :action-view-route-link="'jobManagementDetail'"
               @options-update="onTableChange"
            />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Datatable from '@/components/Datatable'
import _ from 'lodash'
import moment from 'moment'

export default {
   name: 'JobManagement',
   components: {
      Datatable,
   },
   data() {
      return {
         tableHeaders: [
            { text: 'Date', value: 'date', sortable: false },
            { text: 'Voucher', value: '_voucherType', sortable: false },
            { text: 'User', value: 'user_name', sortable: false },
            { text: 'Technician', value: 'technician_name', sortable: false },
            { text: '', value: 'actionView', sortable: false, align: 'end' },
         ],
         tableLoading: false,
         tablePage: 1,
         tablePageLimit: 20,
         tablePageLimitOptions: [20, 50, 100],
         formItemTotal: 0,
         formData: [],
         dataOptions: {
            voucherType: [
               { text: '硬件檢查服務', value: 'hardware' },
               { text: '電腦維修服務', value: 'repair' },
               { text: '電腦螢幕檢查', value: 'screen' },
            ],
         }
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      formatData(data) {
         return _.map(data, (record => {
            const _voucherType = _.find(this.dataOptions.voucherType, service => service.value === record.voucher_type.type)?.text
            return {
               ...record,
               date: moment().format('YYYY-MM-DD HH:mm:ss'),
               _voucherType,
            }
         }))
      },
      async getData() {
         this.tableLoading = true
         const resp = await this.sendRequest({
            type: 'cms_get_job',
            data: {
               limit: this.tablePageLimit,
               page: this.tablePage - 1,
            }
         })
         if (!resp) {
            this.tableLoading = false
            return
         }
         this.formItemTotal = resp.total
         this.formData = this.formatData(resp.data)
         this.tableLoading = false
      },
      onTableChange(options) {
         if (options.itemsPerPage !== this.tablePageLimit) {
            this.tablePage = 1 // reset to first page if view options changed
         } else {
            this.tablePage = options.page
         }

         if (options.sortBy.length > 0) {
            this.tableSortKey = options.sortBy[0]
         }
         this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

         this.tablePageLimit = options.itemsPerPage
         this.getData()
      },
      submitSearch: function() {
         this.getData()
      },
   },
   async mounted() {
      await this.getData()
   }
}
</script>
